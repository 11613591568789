<template>
  <div class="attendance">
    <div class="attendance-header">
      考勤列表
      <el-date-picker
        style="width: 120px; margin-left: 20px"
        v-model="month"
        value-format="yyyy-MM"
        type="month"
        placeholder="选择月"
        @change="dateChange"
        :clearable="clearable"
      >
      </el-date-picker>
      <span
        style="
          color: #aaa;
          font-size: 14px;
          font-weight: 400;
          margin-left: 10px;
        "
        >注："--"表示未打卡</span
      >
      <el-button
        style="float: right"
        @click="exportExcel('out-table', '考勤表')"
        >下载考勤表</el-button
      >
    </div>

    <div class="attendance-body">
      <div class="attendance-main">
        <div class="attendance-main-content">
          <el-table
            :data="clockRecord"
            id="out-table"
            style="width: 100%"
            max-height="250"
          >
            <ex-table-column
              fixed="left"
              prop="employeeName"
              label="姓名"
              :autoFit="true"
            >
            </ex-table-column>
            <ex-table-column
              v-for="(e, i) in clocks"
              :key="i"
              prop="province"
              :label="e.date"
              align="center"
              width="150"
            >
              <template slot-scope="scope">
                <div style="min-width: 100px; height: 20px">
                  <span
                    v-for="(e1, i1) in scope.row.clocks[i].type"
                    :key="i1 + '-1'"
                    class="spanStyle"
                  >
                    <span v-if="e1 === 0">√</span>
                    <span style="color: #ff931e" v-if="e1 === 1">迟到</span>
                    <span style="color: #70c050" v-if="e1 === 2">外勤</span>
                    <span style="color: #e74b47" v-if="e1 === 3">缺卡</span>
                    <span style="color: #ff931e" v-if="e1 === 4">早退</span>
                    <span style="color: #262626" v-if="e1 === 5">--</span>
                    <span style="color: #4c8afc" v-if="e1 === 6">请假</span>
                    <span style="color: #4c8afc" v-if="e1 === 7">出差</span>
                    <span style="color: #4c8afc" v-if="e1 === 8">加班</span>
                    <span style="color: #e74b47" v-if="e1 === 10">旷工</span>
                  </span>
                </div>
              </template>
            </ex-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FileSaver from "file-saver";
import XLSX from "xlsx";
import { queryShift, selectByMonthAdmin } from "@/http/api";
export default {
  data() {
    return {
      enterpriseId: sessionStorage.getItem("enterpriseId"),
      checkedAll: true,
      clearable: false,
      addShiftFlag: false,
      editShiftFlag: false,
      records: [],
      editItem: {},
      month: "",
      shiftId: "",
      pages: {
        total: 0,
        current: 1,
        pageSize: 90,
        pages: 0,
      },
      list: [
        {
          checked: false,
          title: "考勤组1",
        },
        {
          checked: false,
          title: "考勤组2",
        },
        {
          checked: false,
          title: "考勤组3",
        },
        {
          checked: false,
          title: "考勤组4",
        },
        {
          checked: false,
          title: "考勤组5",
        },
        {
          checked: false,
          title: "考勤组6",
        },
      ],
      clockRecord: [],
      clocks: [],
      date: this.$config.getDate(),
    };
  },
  components: {},
  mounted() {
    this.month = this.date.year + "-" + this.date.thisMounth;
    this.selectByMonthAdmin();
  },
  methods: {
    exportExcel(id, fileName) {
      //不加判断table表格导出会有两份数据，是因为fixed造成的，所以要筛选去重
      // 判断要导出的节点中是否有fixed的表格，如果有，转换excel时先将该dom移除，然后append回去，
      var fix = document.querySelector(".el-table__fixed");
      var exportTable;
      if (fix) {
        exportTable = XLSX.utils.table_to_book(
          document.querySelector("#out-table").removeChild(fix)
        );
        document.querySelector("#out-table").appendChild(fix);
      } else {
        exportTable = XLSX.utils.table_to_book(
          document.querySelector("#out-table")
        );
      }
      var exportTableOut = XLSX.write(exportTable, {
        bookType: "xlsx",
        bookSST: true,
        type: "array",
      });
      //sheetjs.xlsx为导出表格的标题名称
      try {
        FileSaver.saveAs(
          new Blob([exportTableOut], { type: "application/octet-stream" }),
          this.month + "考勤.xlsx"
        );
      } catch (e) {
        if (typeof console !== "undefined") console.log(e, exportTableOut);
      }
      return exportTableOut;
    },
    selectByMonthAdmin() {
      let param = {
        enterpriseId: this.enterpriseId,
        mouth: this.month,
      };
      selectByMonthAdmin(param).then((res) => {
        if (res.code === 0) {
          console.log(res);
          this.clockRecord = res.data.clockRecord;
          this.clocks = this.clockRecord[0].clocks;
          return;
          this.records = res.data.records;
          this.records.forEach((e) => {
            e.timeText = "";
            e.shiftTimeList.forEach((e1, i) => {
              // shiftName
              delete e1.createTime;
              delete e1.updateTime;
              e.timeText += e1.workTime + "-" + e1.knockOffTime;
              if (i !== e.shiftTimeList.length - 1) {
                e.timeText += "; ";
              }
            });
          });
        }
      });
    },
    dateChange() {
      this.selectByMonthAdmin();
    },
    timeShowFun(type) {
      this.addShiftFlag = false;
      this.editShiftFlag = false;
      if (type === "save") {
        this.queryShift();
      }
    },
    editClick(e) {
      this.editItem = e;
      this.editShiftFlag = true;
    },
  },
};
</script>

<style lang="less" scoped>
.td5 {
  width: 120px !important;
}
.attendance-header {
  height: 40px;
  line-height: 40px;
  width: calc(100% - 36px);
}
.spanStyle {
  span {
    display: inline-block;
    padding: 0 3px;
  }
}
</style>