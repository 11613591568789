<template>
  <div class="aeraTitle" :class="{ d_jump: className }">
    <span></span>{{ title }}
    <div class="editClass" @click="editClick">修改</div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default() {
        return "";
      },
    },
    className: {
      type: String,
      default() {
        return "";
      },
    },
  },
  methods: {
    editClick() {
      this.$emit("saveClick");
    },
  },
};
</script>

<style lang="less" scoped>
.aeraTitle {
  height: 16px;
  line-height: 16px;
  margin-bottom: 20px;
  position: relative;
  font-size: 14px;
  font-weight: bold;
  color: #262626;
  span {
    position: absolute;
    left: -9px;
    top: 0px;
    width: 2px;
    height: 16px;
    display: inline-block;
    background: #4c8afc;
  }
}
.editClass {
  // display: inline-block;
  float: right;
  margin-right: 50px;
  text-align: center;
  width: 64px;
  height: 28px;
  line-height: 28px;
  background: #ffffff;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  border: 1px solid #dbdbdb;
  font-weight: 400;
  color: #666666;
  font-size: 12px;
  cursor: pointer;
}
</style>