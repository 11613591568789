<template>
  <div class="popup-layer">
    <div class="popup-layer-bg"></div>
    <div class="tips-popup">
      <div class="popup-layer-close" @click="closeFun(0)">
        <svg-icon icon-class="popup-close"></svg-icon>
      </div>
      <div class="tips-popup-header">提示</div>
      <div class="tips-popup-content">
        <div class="tips-popup-text">考勤时间(班次) 什么时候生效？</div>
        <div class="addAttendance-footer">
          <div class="btns-default btns-1" @click="closeFun(1)">立即生效</div>
          <div class="btns-default btns-2" @click="closeFun(2)">明天生效</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    closeFun(type) {
      this.$emit("closeFun", type);
    },
  },
};
</script>

<style lang="less" scoped>
.tips-popup {
  width: 458px;
  height: 200px;
  background: #ffffff;
  border-radius: 8px 8px 8px 8px;
  opacity: 1;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -228px;
  margin-top: -100px;
  .tips-popup-header {
    padding: 16px 20px;
    height: 22px;
    line-height: 22px;
    font-size: 16px;
    font-weight: bold;
    color: #262626;
    text-align: left;
  }

  .tips-popup-text {
    padding: 30px 0;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    color: #262626;
  }
  .addAttendance-footer {
    box-shadow: none;
    text-align: center;
    height: 32px;
    line-height: 32px;
  }
}
</style>