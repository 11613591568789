<template>
  <!-- 基本信息 -->
  <div>
    <areaTitle className="d_jump" title="基本信息" @saveClick="tipsShowFun" />
    <div class="information">
      <div class="i-title">考勤组名称</div>
      <div class="i-ipt-box">
        <input
          type="text"
          @input="inputFun"
          v-model="groupAjax.groupName"
          placeholder="不超过30个字符"
        />
      </div>
      <div class="i-title">考勤组主负责人</div>
      <div class="i-ipt-box i-ipt-select">
        <div class="ipt-list-box">
          <img
            class="marker-img"
            src="http://www.ceshihr.com/download/da-touxiang.png"
          />奈良鹿
        </div>
      </div>
      <div class="i-title">参加考勤的部门和人员</div>
      <div class="i-ipt-list">
        <div class="webkit-scrollbar" @click="showFun">
          <div class="ipt-list-box" v-for="(e, i) in selectArray" :key="i">
            <img v-if="e.userHead" class="marker-img" :src="e.userHead" />
            <img
              v-else
              class="marker-img"
              src="http://www.ceshihr.com/download/nan.png"
            />
            {{ e.employeeName }}
          </div>
        </div>
      </div>
      <div class="popup-layer" v-if="bszShow">
        <div class="popup-layer-bg"></div>
        <div class="personnel-popup">
          <div class="popup-layer-close" @click="closeFun">
            <svg-icon icon-class="popup-close"></svg-icon>
          </div>
          <div class="popup-title">选择考勤的部门和人员</div>
          <div class="personnel-main">
            <div class="personnel-main-l">
              <div class="search-box">
                <input
                  type="text"
                  v-model="queryText"
                  placeholder="搜索员工名字"
                  @input="queryFun"
                />
                <span class="search-btn">搜索</span>
              </div>
              <div class="personnel-list webkit-scrollbar">
                <slot v-for="(e, i) in records">
                  <div class="personnel-list-box" v-if="e.show" :key="i">
                    <el-checkbox
                      :value="e.check"
                      @change="seelctClick(e)"
                      class="checkbox-class"
                      :disabled="!e.disabled"
                    ></el-checkbox
                    ><img
                      v-if="e.userHead"
                      class="list-img"
                      :src="e.userHead"
                    />
                    <img
                      v-else
                      class="list-img"
                      src="http://www.ceshihr.com/download/nan.png"
                    />
                    <span class="personnel-name">{{ e.employeeName }}</span>
                  </div>
                </slot>
              </div>
              <div class="personnel-footer"></div>
            </div>
            <div class="personnel-main-l">
              <div class="personnel-select">
                <div class="personnel-select-l">
                  已选（{{ selectArray.length }}）
                </div>
                <div class="personnel-select-r">
                  <span>清除</span>
                </div>
              </div>
              <div class="personnel-list webkit-scrollbar">
                <div
                  class="personnel-list-box"
                  v-for="(e, i) in selectArray"
                  :key="i"
                >
                  <img v-if="e.userHead" class="list-img" :src="e.userHead" />
                  <img
                    v-else
                    class="list-img"
                    src="http://www.ceshihr.com/download/nan.png"
                  />
                  <span class="personnel-name">{{ e.employeeName }}</span
                  ><span class="personnel-close">
                    <svg-icon
                      @click="closeClcik(e, i)"
                      class="close-svg"
                      icon-class="popup-close"
                    ></svg-icon>
                  </span>
                </div>
              </div>
              <div class="personnel-footer"></div>
            </div>
          </div>
          <div class="personnel-btns">
            <div class="btns-default btns-1" @click="submit">确 定</div>
            <div class="btns-default btns-2" @click="closeFun">取 消</div>
          </div>
        </div>
      </div>
    </div>
    <tipspupup v-if="tipspupupShow" @closeFun="popupClose"></tipspupup>
  </div>
</template>

<script>
import areaTitle from "./areaTitle.vue";
import tipspupup from "../../../components/tipspupup.vue";
import { queryUser, saveGroup } from "@/http/api";
export default {
  data() {
    return {
      tipspupupShow: false,
      bszShow: false,
      enterpriseId: sessionStorage.getItem("enterpriseId"),
      groupName: "",
      queryText: "",
      // records: [],
      selectUser: [],
      selectArray: [],
      userList: [],
      groupAjax: {
        groupName: "",
      },
      // ajaxData: null,
    };
  },
  components: {
    areaTitle,
    tipspupup,
  },
  mounted() {
    this.groupAjax = this.$config.cloneObj(this.ajaxData);
    this.queryUser();
  },
  props: {
    ajaxData: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  methods: {
    popupClose(type) {
      console.log(type);
      if (type === 0) {
        this.tipspupupShow = false;
      } else {
        this.saveGroup(type);
      }
      this.$forceUpdate();
    },
    tipsShowFun() {
      this.tipspupupShow = true;
    },
    saveGroup(type) {
      // this.groupAjax;
      this.groupAjax.enable = type;
      let immediate = 1;
      if (type === 1) {
        immediate = 2;
      }
      this.groupAjax.userList.forEach((element) => {
        element.immediate = immediate;
      });
      console.log(this.groupAjax);
      saveGroup(this.groupAjax).then((res) => {
        if (res.code === 0) {
          console.log(res);
          this.ajaxData.userList = this.$config.cloneObj(
            this.groupAjax.userList
          );

          this.$emit("saveGroup");
          this.$store.commit("MyMessage", "保存成功");
          this.tipspupupShow = false;
        } else {
          this.$store.commit("MyMessage", res.msg);
        }
      });
    },
    saveClick() {},
    queryFun() {
      // queryText
      this.records.forEach((e) => {
        if (this.queryText === "") {
          e.show = true;
        } else {
          if (e.employeeName.indexOf(this.queryText) === -1) {
            e.show = false;
          }
        }
      });
    },
    inputFun() {
      // 判断当前是否能够进行保存操作
      this.userList = [];
      this.selectArray.forEach((e) => {
        this.userList.push({
          userId: e.userId,
          employeeNo: e.employeeNo,
          enterpriseId: e.enterpriseId,
        });
      });
      this.$emit("userList", this.userList, this.groupAjax.groupName);
    },
    submit() {
      // 判断当前是否能够进行保存操作
      if (this.selectArray.length === 0) {
        this.$store.commit("MyMessage", "请选择人员");
        return false;
      }
      this.userList = [];
      this.selectArray.forEach((e) => {
        this.userList.push({
          attendanceGroupId: this.ajaxData.attendanceGroupId,
          userId: e.userId,
          employeeNo: e.employeeNo,
          enterpriseId: e.enterpriseId,
        });
      });
      // console.log(this.userList);
      this.groupAjax.userList = this.userList;
      // this.$emit("userList", this.userList, this.groupName);
      this.closeFun();
    },
    closeFun() {
      // 关闭弹窗
      this.bszShow = false;
    },
    closeClcik(e, index) {
      // 删除已选择项
      this.records.forEach((e1) => {
        if (e1.userId === e.userId) {
          e1.check = false;
        }
      });
      // console.log(this.records);
      this.selectArray.splice(index, 1);
      this.$forceUpdate();
    },
    seelctClick(e) {
      // 选择人员
      e.check = !e.check;
      this.selectArray = [];
      this.records.forEach((e) => {
        if (e.check) {
          this.selectArray.push(e);
        }
      });
      // console.log(this.selectArray);
    },
    showFun() {
      // 显示添加考勤人员弹窗
      this.bszShow = true;
    },
    queryUser() {
      // 查询花名册人员列表
      let param = {
        enterpriseId: this.enterpriseId,
      };
      queryUser(param).then((res) => {
        // console.log(res);
        if (res.code === 0) {
          this.records = res.data.records;
          // console.log(this.groupAjax.userList);
          this.groupAjax.userList.forEach((e1) => {
            this.records.forEach((e) => {
              e.show = true;
              // 1、判断是否属于这个分组
              if (e1.attendanceGroupId === e.groupUserId) {
                // 2、是否已经被选中
                if (e1.userId === e.userId) {
                  e.check = true; //选中状态
                  this.selectArray.push(e);
                  e.disabled = true;
                }
              } else if (e.groupUserId === "") {
                e.check = false; //选中状态
                e.disabled = true;
              }
            });
          });
          // console.log(this.records);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.i-ipt-box {
  width: calc(100% - 50px);
  height: 52px;
  margin-bottom: 19px;
  &.i-ipt-select {
    background: #f2f3f5;
    border-radius: 6px;
    height: auto;
    padding: 13px 0 13px 10px;
    width: calc(100% - 60px);
    .ipt-list-box {
      background: #e6e6e6;
    }
  }
  input {
    width: calc(100% - 38px);
    height: 100%;
    padding: 0 19px;
    border: 1px solid #e6e6e6;
    background: #ffffff;
    border-radius: 6px;
    font-size: 16px;
    font-weight: 400;
    color: #262626;
    &::placeholder {
      color: #bbbbbb;
    }
  }
}
.i-ipt-list {
  width: calc(100% - 50px);
  height: 272px;
  margin-bottom: 19px;
  border: 1px solid #e6e6e6;
  border-radius: 6px;
  cursor: pointer;
  &:hover {
    border: 1px solid #4c8afc;
  }
}
.ipt-list-box {
  height: 26px;
  line-height: 26px;
  display: inline-block;
  background: #efefef;
  border-radius: 13px;
  position: relative;
  padding: 0 9px;
  font-size: 14px;
  font-weight: bold;
  color: #262626;
  margin-right: 10px;
  .user-div {
    display: inline-block;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: #4c8afc;
  }
  .user-img {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    // background: #4c8afc;
    color: #fff;
    font-size: 12px;
    transform: scale(0.83, 0.83);
    transform-origin: 0 0;
    display: inline-block;
  }
  .marker-img {
    width: 16px;
    height: 16px;
    display: inline-block;
    border-radius: 50%;
    margin-right: 4px;
    position: relative;
    top: 3px;
  }
}
.webkit-scrollbar {
  width: calc(100% - 10px);
  height: calc(100% - 20px);
  overflow-y: auto;
  padding: 10px 0 10px 10px;
}
.personnel-popup {
  width: 700px;
  height: 626px;
  background: #ffffff;
  border-radius: 8px 8px 8px 8px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -350px;
  margin-top: -313px;
}
.personnel-main {
  width: calc(100% - 66px);
  margin: 0 32px 28px;
  height: 450px;
  border-radius: 4px;
  border: 1px solid #dbdbdb;
}
.personnel-main-l {
  width: 317px;
  height: calc(100%);
  overflow-y: auto;
  display: inline-block;
  &:first-child {
    border-right: 1px solid #dbdbdb;
    width: 316px;
  }
  .search-box {
    width: 285px;
    height: 30px;
    line-height: 30px;
    background: #ffffff;
    border-radius: 5px;
    opacity: 1;
    border: 1px solid #dbdbdb;
    margin: 20px auto 16px;
    input {
      width: 200px;
      padding: 0 12px;
      color: #262626;
      height: 30px;
      border-radius: 5px;
      &::placeholder {
        color: #bbbbbb;
      }
    }
    .search-btn {
      display: inline-block;
      padding: 0 17px;
      border-left: 1px solid #bbb;
      height: 14px;
      line-height: 14px;
      color: #4c8afc;
      font-size: 12px;
      cursor: pointer;
    }
  }
  .personnel-list {
    width: 100%;
    height: calc(100% - 92px);
    overflow-y: auto;
    &.webkit-scrollbar {
      padding: 0;
    }
    .personnel-list-box {
      width: calc(100% - 17px);
      text-align: left;
      height: 34px;
      line-height: 34px;
      padding-left: 17px;
      margin-bottom: 15px;
    }
    .checkbox-class {
      width: 16px;
      height: 16px;
      position: relative;
      // top: -12px;
      margin-right: 10px;
      float: left;
    }
    .list-img {
      width: 34px;
      height: 34px;
      border-radius: 50%;
      float: left;
      margin-right: 6px;
      border: 1px solid#dbdbdb;
    }
    .user-img {
      width: 34px;
      height: 34px;
      border-radius: 50%;
      float: left;
      margin-right: 6px;
      background: #4c8afc;
      text-align: center;
      color: #fff;
      font-size: 12px;
    }
    .personnel-name {
      height: 34px;
      line-height: 34px;
      font-weight: 400;
      float: left;
    }
  }
  .personnel-footer {
    height: 24px;
    background: #ffffff;
    box-shadow: 0px -3px 4px 1px rgba(0, 0, 0, 0.06);
    position: relative;
  }
  .personnel-select {
    width: calc(100% - 50px);
    height: 32px;
    line-height: 32px;
    margin: 20px auto 16px;
    .personnel-select-l {
      width: 50%;
      display: inline-block;
      height: 32px;
      text-align: left;
      color: #5a6370;
      font-size: 12px;
    }
    .personnel-select-r {
      width: 50%;
      display: inline-block;
      height: 32px;
      text-align: right;
      span {
        color: #4c8afc;
        font-size: 12px;
        cursor: pointer;
      }
    }
  }
  .personnel-close {
    float: right;
    width: 16px;
    height: 16px;
    position: relative;
    cursor: pointer;
    .close-svg {
      // font-size: 16px;
      font-weight: bold;
      position: relative;
      right: 15px;
      top: 2px;
    }
  }
}
.personnel-btns {
  width: calc(100% - 64px);
  margin: 0 32px;
  text-align: right;
}
</style>
<style lang="less">
.personnel-list {
  .el-checkbox__inner {
    width: 16px;
    height: 16px;
  }
}
</style>