<template>
  <!-- 考勤扣款 -->
  <div>
    <areaTitle className="d_jump" title="考勤扣款" @saveClick="tipsShowFun" />
    <div class="information">
      <div class="switch-box">
        <el-switch
          class="switch-style"
          v-model="value1"
          active-color="#13ce66"
          inactive-color="#bbbbbb"
        >
        </el-switch
        >已开启考勤扣款<span class="time-edit" @click="deductionShow = true"
          >更改</span
        >
      </div>
      <div class="i-clickIn time-height">
        <div class="clickIn-list">
          <div class="clickIn-box clickIn-box-header">
            <div class="clickIn-box-1">名称</div>
            <div class="clickIn-box-2">处罚条件</div>
            <div class="clickIn-box-3">处罚金额</div>
          </div>
          <div class="clickIn-box">
            <div class="clickIn-box-1">迟到处罚</div>
            <div class="clickIn-box-2">{{ text1 }}</div>
            <div class="clickIn-box-3">
              <span class="span3">{{ deduction.latePenaltyMoney }}元/次</span>
            </div>
          </div>
          <div class="clickIn-box">
            <div class="clickIn-box-1">早退处罚</div>
            <div class="clickIn-box-2">{{ text2 }}</div>
            <div class="clickIn-box-3">
              <span class="span3">{{ deduction.earlyLeaveMoney }}元/次</span>
            </div>
          </div>
          <div class="clickIn-box">
            <div class="clickIn-box-1">缺卡处罚</div>
            <div class="clickIn-box-2">{{ text3 }}</div>
            <div class="clickIn-box-3">
              <span class="span3">{{ deduction.lackPenaltyMoney }}元/次</span>
            </div>
          </div>
          <div class="clickIn-box">
            <div class="clickIn-box-1">旷工处罚</div>
            <div class="clickIn-box-2"><span class="span4">--</span></div>
            <div class="clickIn-box-3">
              <span class="span3" v-if="deduction.minerPenaltyType !== 0">{{
                text4
              }}</span>
              <span v-else class="span3"
                >{{ deduction.minerPenaltyMoney }}元/天</span
              >
            </div>
          </div>
        </div>
      </div>
      <div class="popup-layer" v-if="deductionShow">
        <div class="popup-layer-bg"></div>
        <div class="time-popup">
          <div class="popup-layer-close" @click="deductionShow = false">
            <svg-icon icon-class="popup-close"></svg-icon>
          </div>
          <div class="popup-title">考勤扣款</div>
          <div class="time-list webkit-scrollbar">
            <div class="time-list-box time-list-box-header">
              <div class="time-list-box-1">名称</div>
              <div class="time-list-box-2">处罚条件</div>
              <div class="time-list-box-3">处罚金额</div>
            </div>
            <div class="time-list-box">
              <div class="time-list-box-1">迟到处罚</div>
              <div class="time-list-box-2 list-box-select">
                <el-select
                  v-model="deduction.latePenaltyTimes"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
              <div class="time-list-box-3">
                <input type="text" v-model="deduction.latePenaltyMoney" />元/次
              </div>
            </div>
            <div class="time-list-box">
              <div class="time-list-box-1">早退处罚</div>
              <div class="time-list-box-2 list-box-select">
                <el-select
                  v-model="deduction.earlyLeavePenaltyTimes"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
              <div class="time-list-box-3">
                <input type="text" v-model="deduction.earlyLeaveMoney" />元/次
              </div>
            </div>
            <div class="time-list-box">
              <div class="time-list-box-1">缺卡处罚</div>
              <div class="time-list-box-2 list-box-select">
                <el-select
                  v-model="deduction.lackPenaltyTimes"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in option2"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
              <!-- <div class="time-list-box-3 i-disable"> -->
              <div class="time-list-box-3">
                <input type="text" v-model="deduction.lackPenaltyMoney" />元/次
              </div>
            </div>
            <div class="time-list-box">
              <div class="time-list-box-1">旷工处罚</div>
              <div class="time-list-box-2 list-box-select">
                <el-select
                  v-model="deduction.minerPenaltyType"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in option1"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
              <div class="time-list-box-3">
                <input type="text" v-model="deduction.minerPenaltyMoney" />元/天
              </div>
            </div>
          </div>
          <div class="time-footer">
            <div class="btns-default btns-1" @click="submit">确 定</div>
            <div class="btns-default btns-2" @click="deductionShow = false">
              取 消
            </div>
          </div>
        </div>
      </div>
    </div>
    <tipspupup v-if="tipspupupShow" @closeFun="popupClose"></tipspupup>
  </div>
</template>

<script>
import areaTitle from "./areaTitle.vue";
import tipspupup from "../../../components/tipspupup.vue";
import { saveGroup } from "@/http/api";
export default {
  data() {
    return {
      tipspupupShow: false,
      deductionShow: false,
      value1: true,
      value2: "",
      options: [
        {
          value: 0,
          label: "不减免",
        },
        {
          value: 1,
          label: "减免1次",
        },
        {
          value: 2,
          label: "减免2次",
        },
        {
          value: 3,
          label: "减免3次",
        },
        {
          value: 4,
          label: "减免5次",
        },
      ],
      option2: [
        {
          value: 0,
          label: "不减免",
        },
        {
          value: 1,
          label: "减免1次",
        },
        {
          value: 2,
          label: "减免2次",
        },
        {
          value: 3,
          label: "减免3次",
        },
        {
          value: 4,
          label: "减免4次",
        },
        {
          value: 5,
          label: "扣除日薪",
          value1: 2,
        },
        {
          value: 6,
          label: "半薪",
          value1: 1,
        },
      ],
      option1: [
        {
          value: 0,
          label: "请选择",
        },
        {
          value: 1,
          label: "三薪",
        },
        {
          value: 2,
          label: "双薪",
        },
        {
          value: 3,
          label: "扣除日薪",
        },
      ],
      value: "",
      text1: "",
      text2: "",
      text3: "",
      text4: "",
      deduction: {
        // enable: 1,
        // latePenaltyTimes: 0,
        // latePenaltyMoney: 0,
        // earlyLeavePenaltyTimes: 0,
        // earlyLeaveMoney: 0,
        // minerPenaltyTimes: 0,
        // minerPenaltyType: 0,
        // minerPenaltyMoney: 0,
        // lackPenaltyTimes: 0,
        // lackPenaltyMoney: 0,
      },
      groupAjax: {},
    };
  },
  // props: {
  //   deduction: {
  //     type: Object,
  //     default() {
  //       return {};
  //     },
  //   },
  // },
  components: {
    areaTitle,
    tipspupup,
  },
  props: {
    ajaxData: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  mounted() {
    this.groupAjax = this.$config.cloneObj(this.ajaxData);
    this.deduction = this.ajaxData.deduction;
    // console.log(this.deduction);
    if (this.deduction.enable === 2) {
      this.value1 = false;
    } else {
      this.value1 = true;
    }
    this.batch();
  },
  methods: {
    popupClose(type) {
      if (type === 0) {
        this.tipspupupShow = false;
      } else {
        this.groupAjax.enable = type;
        this.batch("submit");
      }
      this.$forceUpdate();
    },
    tipsShowFun() {
      this.tipspupupShow = true;
    },
    saveGroup(param) {
      console.log(param);
      this.groupAjax.deduction = param;
      saveGroup(this.groupAjax).then((res) => {
        if (res.code === 0) {
          console.log(res);
          this.ajaxData.deduction = this.$config.cloneObj(
            this.groupAjax.deduction
          );
          this.$emit("saveGroup");
          this.$store.commit("MyMessage", "保存成功");
          this.tipspupupShow = false;
        } else {
          this.$store.commit("MyMessage", res.msg);
        }
      });
    },
    submit() {
      // console.log(this.deduction);
      this.batch();
      this.deductionShow = false;
    },
    batch(type) {
      this.options.forEach((e, i) => {
        if (this.deduction.latePenaltyTimes === e.value) {
          this.text1 = e.label;
        }
        if (this.deduction.earlyLeavePenaltyTimes === e.value) {
          this.text2 = e.label;
        }
        if (this.deduction.lackPenaltyTimes === e.value) {
          this.text3 = e.label;
        }
      });
      this.option2.forEach((e, i) => {
        if (this.deduction.lackPenaltyTimes === e.value) {
          this.text3 = e.label;
        }
      });
      this.option1.forEach((e, i) => {
        if (this.deduction.minerPenaltyType === e.value) {
          if (this.deduction.minerPenaltyType === 0) {
            this.text4 = "";
          } else {
            this.text4 = e.label;
            this.deduction.minerPenaltyMoney = 0;
          }
        }
      });
      if (type === "submit") {
        let param = this.$config.cloneObj(this.deduction);
        // console.log(this.deduction.lackPenaltyTimes);
        if (this.deduction.lackPenaltyTimes === 5) {
          param.lackPenaltyTimes = 0;
          param.lackPenaltyType = 2;
        }
        if (this.deduction.lackPenaltyTimes === 6) {
          param.lackPenaltyTimes = 0;
          param.lackPenaltyType = 1;
        }
        if (!this.value1) {
          param.enable = 2;
        } else {
          param.enable = 1;
        }

        // this.saveGroup = param;
        this.saveGroup(param);
        // this.$emit("deductionFun", param);
      }
    },
  },
};
</script>
<style lang="less" scoped>
.i-clickIn {
  width: calc(100% - 50px);
}
.time-popup {
  width: 700px;
  height: 494px;
  background: #ffffff;
  border-radius: 8px 8px 8px 8px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -350px;
  margin-top: -247px;

  .time-list {
    margin: 0 10px;
    padding: 0 22px;
    height: 342px;
    overflow-y: auto;
    .time-list-box {
      text-align: left;
      padding: 21px 0 18px;
      display: table;
      width: 100%;
      border-bottom: 1px dashed #dbdbdb;

      &.time-list-box-header {
        height: 50px;
        background: #f2f3f5;
        line-height: 50px;
        padding: 0;
        border-bottom: none;
        .time-list-box-1,
        .time-list-box-2 {
          color: #5a6370;
          font-weight: 400;
        }
      }
      .time-list-box-1 {
        width: 221px;
        color: #262626;
        font-weight: bold;
        padding-left: 25px;
        position: relative;
        display: inline-block;
        vertical-align: middle;
        display: table-cell; /*cell垂直居中，如果外层div不为table则tablecell须有高度*/
      }
      .time-list-box-2 {
        width: 255px;
        position: relative;
        color: #262626;
        font-weight: bold;
        display: inline-block;
        vertical-align: middle;
        display: table-cell; /*cell垂直居中，如果外层div不为table则tablecell须有高度*/
      }
      .time-list-box-3 {
        width: calc(100% - 489px);
        position: relative;
        color: #262626;
        font-weight: bold;
        display: inline-block;
        vertical-align: middle;
        display: table-cell; /*cell垂直居中，如果外层div不为table则tablecell须有高度*/
        &.i-disable {
          input {
            background: #efefef;
            border: 1px solid #efefef;
          }
          color: rgba(38, 38, 38, 0.3);
        }
        input {
          width: 92px;
          height: 30px;
          background: #ffffff;
          border-radius: 4px 4px 4px 4px;
          border: 1px solid #dbdbdb;
          margin-right: 10px;
          text-align: center;
          font-weight: bold;
          font-size: 14px;
          color: #262626;
        }
      }
    }
  }
}
.time-footer {
  padding: 15px 32px 0;
  text-align: right;
}
.time-default {
  font-weight: bold;
  color: #262626;
  font-size: 12px;
  margin-bottom: 10px;
}
.time-height {
  height: auto;
}
.time-edit {
  font-size: 12px;
  font-weight: 400;
  color: #4c8afc;
  margin-left: 20px;
  cursor: pointer;
}
</style>
<style lang="less" scoped>
.switch-box {
  margin-bottom: 14px;
  font-weight: bold;
  color: #262626;
  height: 30px;
  line-height: 30px;
}
.time-height {
  height: auto;
}
.switch-style {
  margin-right: 18px;
  height: 30px;
  line-height: 30px;
}
.time-edit {
  font-size: 12px;
  font-weight: 400;
  color: #4c8afc;
  margin-left: 20px;
  cursor: pointer;
}
.clickIn-box-header {
  .clickIn-box-1 {
    color: #5a6370;
    font-weight: 400;
  }
  .clickIn-box-2 {
    color: #5a6370;
    font-weight: 400;
  }
}
.clickIn-box-1 {
  width: 188px;
  display: inline-block;
  font-weight: bold;
  color: #262626;
}
.clickIn-box-2 {
  width: 100px;
  display: inline-block;
  margin-left: 10px;
  font-weight: bold;
  color: #262626;
}
.clickIn-box-3 {
  width: calc(100% - 318px);
  display: inline-block;
  margin-left: 20px;
  text-align: right;
}
</style>
<style lang="less">
.list-box-select {
  .el-select {
    width: 139px;
    height: 30px;
  }
  .el-input__inner {
    height: 30px;
  }
  .el-input__icon {
    line-height: 30px;
    font-weight: bold;
    color: #262626 !important;
    font-size: 18px !important;
  }
}
</style>
