<template>
  <div class="attendance">
    <div class="attendance-header">班次设置</div>
    <div class="attendance-body">
      <div class="attendance-main">
        <div class="attendance-main-top">
          <div class="attendance-btn" @click="addShiftFlag = true">
            + 新建班次
          </div>
        </div>
        <div class="attendance-main-content">
          <table class="attendance-table">
            <thead>
              <tr>
                <th class="b-color border-right">
                  <!-- <el-checkbox
                    class="checkbox-class"
                    v-model="checkedAll"
                  ></el-checkbox> -->
                  班次名称
                </th>
                <!-- <th class="b-color td2">考勤人数</th> -->
                <th class="b-color td22">考勤时间（班次）</th>
                <th class="b-color">创建人</th>
                <th class="b-color">最后更新时间</th>
                <th class="b-color center border-left">操作</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(e, i) in records" :key="i">
                <td class="border-right td1">
                  <!-- <el-checkbox class="checkbox-class"></el-checkbox> -->
                  {{ e.shiftName }}
                </td>
                <!-- <td>2</td> -->
                <td class="td22">
                  <span class="td-div">
                    {{ e.timeText }}
                  </span>
                </td>
                <td class="td4">
                  <div class="people-div">
                    <img
                      class="marker-img"
                      src="http://www.ceshihr.com/download/da-touxiang.png"
                    />管理员
                  </div>
                </td>
                <td class="td5">
                  {{ e.updateTime }}
                </td>
                <td class="td6 center border-left">
                  <div class="div-width">
                    <span class="t-edit" @click="editClick(e)">编辑</span>
                    <span class="t-del">删除</span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="paging">
            <el-pagination
              :page-size="20"
              :pager-count="11"
              layout="prev, pager, next"
              :total="60"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
    <editShift
      :editItem="editItem"
      v-if="editShiftFlag"
      @closeFun="timeShowFun"
    />
    <addShift
      :editItem="editItem"
      v-if="addShiftFlag"
      @closeFun="timeShowFun"
    />
  </div>
</template>

<script>
import { queryShift } from "@/http/api";
import editShift from "@/components/editShift";
import addShift from "@/components/addShift";
export default {
  data() {
    return {
      enterpriseId: sessionStorage.getItem("enterpriseId"),
      checkedAll: true,
      addShiftFlag: false,
      editShiftFlag: false,
      records: [],
      editItem: {},
      shiftId: "",
      pages: {
        total: 0,
        current: 1,
        pageSize: 90,
        pages: 0,
      },
      list: [
        {
          checked: false,
          title: "考勤组1",
        },
        {
          checked: false,
          title: "考勤组2",
        },
        {
          checked: false,
          title: "考勤组3",
        },
        {
          checked: false,
          title: "考勤组4",
        },
        {
          checked: false,
          title: "考勤组5",
        },
        {
          checked: false,
          title: "考勤组6",
        },
      ],
    };
  },
  components: {
    editShift,
    addShift,
  },
  mounted() {
    this.queryShift();
  },
  methods: {
    queryShift() {
      let param = {
        enterpriseId: this.enterpriseId,
        pageNo: this.pages.current,
        pageSize: this.pages.pageSize,
      };
      queryShift(param).then((res) => {
        if (res.code === 0) {
          console.log(res);
          this.records = res.data.records;
          this.records.forEach((e) => {
            e.timeText = "";
            e.shiftTimeList.forEach((e1, i) => {
              // shiftName
              delete e1.createTime;
              delete e1.updateTime;
              e.timeText += e1.workTime + "-" + e1.knockOffTime;
              if (i !== e.shiftTimeList.length - 1) {
                e.timeText += "; ";
              }
            });
          });
        }
      });
    },
    timeShowFun(type) {
      console.log(111);
      this.addShiftFlag = false;
      this.editShiftFlag = false;
      if (type === "save") {
        this.queryShift();
      }
    },
    editClick(e) {
      this.editItem = e;
      this.editShiftFlag = true;
    },
  },
};
</script>

<style lang="less" scoped>
.td5 {
  width: 120px !important;
}
</style>