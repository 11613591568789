<template>
  <div class="attendance">
    <div class="attendance-header">考勤组设置</div>
    <div class="attendance-body">
      <div class="attendance-main">
        <div class="attendance-main-top">
          <div class="attendance-btn">
            <router-link to="/addAttendance">+ 新建考勤组</router-link>
          </div>
        </div>
        <div class="attendance-main-content">
          <table class="attendance-table">
            <thead>
              <tr>
                <th class="b-color border-right">
                  <!-- <el-checkbox
                    class="checkbox-class"
                    v-model="checkedAll"
                  ></el-checkbox> -->
                  考勤组名称
                </th>
                <th class="b-color td2">考勤人数</th>
                <th class="b-color">考勤时间（班次）</th>
                <th class="b-color">考勤扣款</th>
                <th class="b-color center">考勤负责人</th>
                <th class="b-color center border-left">操作</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(e, i) in records" :key="i">
                <td class="border-right td1">
                  <!-- <el-checkbox class="checkbox-class"></el-checkbox> -->
                  {{ e.groupName }}
                </td>
                <td class="td2">{{ e.userList.length }}</td>
                <td>
                  <span class="td-div">
                    <slot v-for="(e1, i1) in e.timeList">
                      <span :key="i1"
                        ><slot v-if="i1 === 0">每</slot>{{ e1.clockWeekText }}
                        {{ e1.timeText }}
                      </span>
                    </slot>
                  </span>
                </td>
                <td class="td4">
                  <span class="color1" v-if="e.deduction.enable === 1"
                    >已设置</span
                  >
                  <span class="color2" v-else>未设置</span>
                </td>
                <td class="td5 center">
                  <div class="people-div">
                    <img
                      class="marker-img"
                      src="http://www.ceshihr.com/download/da-touxiang.png"
                    />管理员
                  </div>
                </td>
                <td class="td6 center border-left">
                  <div class="div-width">
                    <span class="t-edit" @click="editClick(e)">编辑</span>
                    <span class="t-del" @click="deleteGroup(e)">删除</span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="paging" v-if="pages.pages > 1">
            <el-pagination
              :page-size="pages.pageSize"
              :pager-count="pages.current"
              layout="prev, pager, next"
              :total="pages.total"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { queryGroup, deleteGroup } from "@/http/api";
export default {
  data() {
    return {
      checkedAll: true,
      enterpriseId: sessionStorage.getItem("enterpriseId"),
      list: [
        {
          checked: false,
          title: "考勤组1",
        },
        {
          checked: false,
          title: "考勤组2",
        },
        {
          checked: false,
          title: "考勤组3",
        },
        {
          checked: false,
          title: "考勤组4",
        },
        {
          checked: false,
          title: "考勤组5",
        },
        {
          checked: false,
          title: "考勤组6",
        },
      ],
      records: [],
      pages: {
        total: 0,
        current: 1,
        pageSize: 10,
        pages: 0,
      },
    };
  },
  mounted() {
    this.queryGroup();
  },
  methods: {
    editClick(e) {
      sessionStorage.setItem("records", JSON.stringify(e));
      this.$router.push("/editAttendance");
    },
    deleteGroup(e) {
      if (e.userList.length > 0) {
        this.$store.commit("MyMessage", "该考情组下存在人员");
        return false;
      }
      deleteGroup(e.attendanceGroupId).then((res) => {
        console.log(res);
        if (res.code === 0) {
          this.queryGroup();
          this.$store.commit("MyMessage", "删除成功");
        } else {
          this.$store.commit("MyMessage", res.msg);
        }
      });
    },
    queryGroup() {
      let param = {
        enterpriseId: this.enterpriseId,
      };
      queryGroup(param).then((res) => {
        console.log(res);
        if (res.code === 0) {
          this.records = res.data.records;
          this.pages.total = res.data.total;
          this.pages.pageSize = res.data.size;
          this.pages.current = res.data.current;
          this.pages.pages = res.data.pages;
          this.records.forEach((e, i) => {
            e.timeList.forEach((e1, i1) => {
              e1.clockWeekText = "";
              e1.timeText = "";
              // console.log(e1);
              let array = this.$config.formatWeekFromWeek(e1);

              array.forEach((element, i) => {
                e1.clockWeekText += element;
                if (i !== array.length - 1) {
                  e1.clockWeekText += ",";
                }
              });

              // console.log(e1.shift);
              e1.shift.shiftTimeList.forEach((e3, i3) => {
                e1.timeText += e3.workTime + "-" + e3.knockOffTime;
                if (i3 !== e1.shift.shiftTimeList.length - 1) {
                  e1.timeText += ",";
                }
              });
            });
          });
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
</style>