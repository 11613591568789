<template>
  <div class="popup-layer">
    <div class="popup-layer-bg"></div>
    <div class="time-popup">
      <div class="popup-layer-close" @click="closeFun">
        <svg-icon icon-class="popup-close"></svg-icon>
      </div>
      <div class="popup-title">修改班次</div>
      <div class="i-title">班次名称</div>
      <div class="i-ipt-box">
        <input
          type="text"
          v-model="ajaxData.shiftName"
          placeholder="不超过30个字符"
        />
      </div>
      <div class="i-title title-b">
        打卡时段 <span>（计算工时，必须选择）</span>
      </div>

      <div class="time-list webkit-scrollbar">
        <div class="shift-main">
          <div class="shift-main-box" v-for="(e, i) in shiftTimeList" :key="i">
            <div class="shift-l">
              <p class="shift-p">上班打卡</p>
              <div class="shift-box">
                <i class="el-icon-arrow-down"></i>
                <el-time-select
                  placeholder="起始时间"
                  v-model="e.workTime"
                  value-format="HH:mm"
                  format="HH:mm"
                  :picker-options="{
                    disabledDate: (time) => {
                      return dataTime.startTimeData(e.knockOffTime);
                    },
                    start: '00:00',
                    step: '00:10',
                    end: '23:30',
                  }"
                >
                </el-time-select>
              </div>
            </div>
            <div class="shift-c">至</div>
            <div class="shift-r">
              <p class="shift-p">下班打卡</p>
              <div class="shift-box">
                <i class="el-icon-arrow-down"></i>
                <el-time-select
                  placeholder="起始时间"
                  v-model="e.knockOffTime"
                  value-format="HH:mm"
                  format="HH:mm"
                  :picker-options="{
                    disabledDate: (time) => {
                      return dataTime.endTimeData(time);
                    },
                    start: '00:00',
                    step: '00:10',
                    end: '23:30',
                    minTime: e.workTime,
                  }"
                >
                </el-time-select>
              </div>
            </div>
            <div class="shift-del" @click="del(e, i)" v-if="i !== 0">删除</div>
          </div>
        </div>
        <div class="shift-main-add">
          <span @click="addTime">+ 新增打卡时段</span>
        </div>
      </div>
      <div class="time-footer">
        <div class="btns-default btns-1" @click="submit()">确 定</div>
        <div class="btns-default btns-2" @click="closeFun">取 消</div>
      </div>
    </div>
    <tipspupup v-if="tipspupupShow" @closeFun="popupClose"></tipspupup>
  </div>
</template>

<script>
import { queryShift, saveShift } from "@/http/api";
import tipspupup from "@/components/tipspupup.vue";
export default {
  data() {
    return {
      enterpriseId: sessionStorage.getItem("enterpriseId"),
      // value1: "",
      tipspupupShow: false,
      timePopup: false,
      shiftPopup: false,
      pages: {},
      value1: "",
      value2: "",
      ajaxData: {},
      shiftTimeList: [],
    };
  },
  props: {
    editItem: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  components: {
    tipspupup,
  },
  mounted() {
    console.log(this.editItem);
    this.ajaxData = this.$config.cloneObj(this.editItem);
    this.shiftTimeList = this.ajaxData.shiftTimeList;
    this.$forceUpdate();
  },
  methods: {
    popupClose(type) {
      // if (type === 0) {
      //   this.tipspupupShow = false;
      // } else {
      //   this.groupAjax.enable = type;
      //   this.saveGroup(type);
      // }
      // this.$forceUpdate();
      if (type === 0) {
        this.tipspupupShow = false;
      } else {
        this.ajaxData.enable = type;
        saveShift(this.ajaxData).then((res) => {
          if (res.code === 0) {
            this.$emit("closeFun", "save");
            this.$store.commit("MyMessage", "修改成功");
          }
        });
      }
      this.$forceUpdate();
    },
    closeFun(e) {
      this.$emit("closeFun", "close");
    },
    queryShift() {
      let param = {};
      queryShift().then((res) => {
        if (res.code === 0) {
          console.log(res.data);
        }
      });
    },
    submit() {
      console.log(this.Fn());
      if (this.ajaxData.shiftName === "") {
        this.$store.commit("MyMessage", "请输入班次名称");
        return false;
      }
      if (!this.Fn()) {
        this.$store.commit("MyMessage", "打卡时间段时间不能重合");
        return false;
      }
      this.shiftTimeList.forEach((e) => {
        if (e.workTime > e.knockOffTime) {
          this.$store.commit("MyMessage", "上班打卡时间必须小于下班打卡时间");
          return false;
        }
      });
      this.tipspupupShow = true;
    },
    Fn() {
      //true的话表示没有重叠，false表示有重叠
      for (let k in this.shiftTimeList) {
        if (!this.judege(k)) {
          return false;
        }
      }
      return true;
    },
    judege(idx) {
      let dateAr = this.shiftTimeList;
      for (let k in dateAr) {
        if (idx !== k) {
          if (
            dateAr[k].workTime <= dateAr[idx].workTime &&
            dateAr[k].knockOffTime > dateAr[idx].workTime
          ) {
            return false;
          }
          if (
            dateAr[k].workTime < dateAr[idx].knockOffTime &&
            dateAr[k].knockOffTime >= dateAr[idx].knockOffTime
          ) {
            return false;
          }
        }
      }
      return true;
    },

    addTime() {
      this.shiftTimeList.push({
        workTime: "09:00",
        knockOffTime: "18:00",
      });
    },
    del(e, index) {
      if (this.shiftTimeList.length === 1) {
        this.$store.commit("MyMessage", "至少保留一个打卡时间段");
        return false;
      }
      if (e.shiftId) {
        this.ajaxData.deleteShiftTimeList.push(e);
      }
      this.shiftTimeList.splice(index, 1);
    },
    timeShowFun() {
      this.shiftPopup = false;
      this.timePopup = true;
    },
    shiftShowFun() {
      this.timePopup = false;
      this.shiftPopup = true;
    },
  },
};
</script>

<style lang="less" scoped>
.shift-main-add {
  font-size: 12px;
  font-weight: bold;
  color: #4c8afc;
  text-align: center;
  padding: 20px 0;
  cursor: pointer;
}
.i-title {
  padding: 0 32px;
  text-align: left;
  span {
    color: #bbbbbb;
  }
  &.title-b {
    margin-bottom: 20px;
  }
}
.i-ipt-box {
  padding: 0 32px;
  height: 52px;
  margin-bottom: 19px;
  &.i-ipt-select {
    background: #f2f3f5;
    border-radius: 6px;
    height: auto;
    padding: 13px 0 13px 10px;
    width: 476px;
    .ipt-list-box {
      background: #e6e6e6;
    }
  }
  input {
    width: calc(100% - 38px);
    height: 100%;
    padding: 0 19px;
    border: 1px solid #e6e6e6;
    background: #ffffff;
    border-radius: 6px;
    font-size: 16px;
    font-weight: bold;
    color: #262626;
    &::placeholder {
      color: #bbbbbb;
      font-weight: 400;
    }
  }
}
.time-footer {
  padding: 15px 32px 0;
  text-align: right;
}

.time-popup {
  width: 700px;
  min-height: 540px;
  background: #ffffff;
  border-radius: 8px 8px 8px 8px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -350px;
  margin-top: -290px;
  padding-bottom: 34px;
  text-align: left;

  .time-list {
    margin: 0 32px;
    height: 342px;
    overflow-y: auto;
    .shift-main {
      background: #f2f3f5;
      border-radius: 6px 6px 6px 6px;
      padding-bottom: 20px;
    }
    .shift-main-box {
      width: 314px;
      margin: 0 auto;
      height: 98px;
      border-bottom: 1px dashed #dbdbdb;
      position: relative;
      .shift-del {
        display: inline-block;
        position: absolute;
        right: -47px;
        top: 54px;
        color: #4c8afc;
        cursor: pointer;
        user-select: none;
      }
      &:last-child {
        border-bottom: none;
      }
      .shift-l {
        width: 130px;
        display: inline-block;
        position: relative;
      }
      .shift-c {
        width: calc(100% - 260px);
        display: inline-block;
        position: relative;
        text-align: center;
        line-height: 98px;
      }
      .shift-r {
        width: 130px;
        display: inline-block;
        position: relative;
      }
      .shift-box {
        width: 100%;
        background: #fff;
        position: relative;
        .el-icon-arrow-down {
          position: absolute;
          top: 13px;
          right: 12px;
          font-size: 20px;
          color: #dbdbdb;
          font-weight: bold;
          z-index: 0;
        }
      }
      .shift-p {
        margin-top: 17px;
        margin-bottom: 5px;
        text-align: left;
      }
    }
  }
}
</style>

<style lang="less">
.time-popup {
  .el-date-editor.el-input {
    width: 100%;
  }
  .el-date-editor {
    .el-input__prefix,
    .el-input__suffix {
      display: none;
    }
    .el-input__inner {
      font-size: 20px;
      font-weight: bold;
      color: #262626;
      background: none;
      height: 44px;
      padding-left: 10px;
      cursor: pointer;
      &::placeholder {
        font-weight: 400;
        font-size: 18px;
      }
    }
  }
}
</style>