<template>
  <!-- 打卡地址 -->
  <div>
    <areaTitle className="d_jump" title="打卡地址" @saveClick="tipsShowFun" />
    <div class="information">
      <div class="i-btn-box">
        <div class="i-btn" @click="showFun">+新增考勤地点</div>
      </div>
      <div class="i-clickIn">
        <div class="clickIn-list">
          <div class="clickIn-box clickIn-box-header">
            <div class="clickIn-box-1">考勤地点名字</div>
            <div class="clickIn-box-2">允许打卡范围</div>
            <div class="clickIn-box-3">操作</div>
          </div>
          <slot v-if="placeList.length > 0">
            <div class="clickIn-box" v-for="(e, i) in placeList" :key="i">
              <div class="clickIn-box-1">{{ e.placeName }}</div>
              <div class="clickIn-box-2">
                <el-select v-model="e.range" disabled placeholder="请选择">
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
              <div class="clickIn-box-3">
                <!-- <span class="span1">修改</span> -->
                <span class="span2" @click="delFun(e, i)">删除</span>
              </div>
            </div>
          </slot>
          <slot v-else>
            <div class="no-list">请先设置考勤地点！</div>
          </slot>
        </div>
      </div>
      <div class="popup-layer" v-if="show">
        <div class="popup-layer-bg"></div>
        <div class="time-popup">
          <div class="popup-layer-close" @click="show = false">
            <svg-icon icon-class="popup-close"></svg-icon>
          </div>
          <div class="popup-title">打卡地点</div>
          <div class="time-list">
            <div class="album" id="album">
              <div class="album-box">
                <div class="album-box-l">
                  <input
                    type="text"
                    v-model="keywords"
                    id="tipinput"
                    class="album-i"
                    placeholder="请输入地名"
                  />
                  <span class="album-s" @click="select">搜索</span>
                </div>
                <div class="album-box-r">
                  <el-select v-model="location.range" placeholder="请选择">
                    <el-option
                      v-for="item in options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </div>
              </div>
              <div
                class="album-popup webkit-scrollbar"
                v-if="poiList.length > 0"
              >
                <div
                  class="album-popup-box"
                  v-for="(e, i) in poiList"
                  :key="i"
                  @click="poiClick(e, i)"
                >
                  {{ e.name }}
                  <i v-if="e.select" class="el-icon-check"></i>
                </div>
              </div>
            </div>
          </div>
          <div class="time-footer">
            <div class="btns-default btns-1" @click="submit">确 定</div>
            <div class="btns-default btns-2">取 消</div>
          </div>
        </div>
      </div>
    </div>
    <tipspupup v-if="tipspupupShow" @closeFun="popupClose"></tipspupup>
  </div>
</template>

<script>
import AMapLoader from "@amap/amap-jsapi-loader";
import areaTitle from "./areaTitle.vue";
import tipspupup from "../../../components/tipspupup.vue";
import { saveGroup } from "@/http/api";
export default {
  created() {},
  data() {
    return {
      tipspupupShow: false,
      activeIndex: "1",
      options: [],
      value: "",
      map: null,
      ipt1: "北京大学",
      position: [],
      poiList: [],
      mapNew: null,
      keywords: "",
      show: false,
      auto: null,
      placeSearch: null,
      autoOptions: {
        input: "tipinput",
      },
      placeList: [],
      location: {
        longitude: null,
        dimension: null,
        placeName: null,
        range: 100,
        placeDescribe: null,
      },
      groupAjax: {},
    };
  },
  props: {
    ajaxData: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  components: {
    areaTitle,
    tipspupup,
  },
  mounted() {
    // this.initAMap();
    this.groupAjax = this.$config.cloneObj(this.ajaxData);
    this.placeList = this.groupAjax.placeList;
    for (let index = 100; index < 10000; index += 100) {
      this.options.push({
        value: index,
        label: index + " 米",
      });
    }
  },
  methods: {
    popupClose(type) {
      console.log(type);
      if (type === 0) {
        this.tipspupupShow = false;
      } else {
        this.saveGroup(type);
      }
      this.$forceUpdate();
    },
    tipsShowFun() {
      this.tipspupupShow = true;
    },
    saveGroup(type) {
      this.groupAjax.enable = type;
      saveGroup(this.groupAjax).then((res) => {
        if (res.code === 0) {
          console.log(res);
          this.ajaxData.placeList = this.$config.cloneObj(
            this.groupAjax.placeList
          );
          this.$emit("saveGroup");
          this.$store.commit("MyMessage", "保存成功");
          this.tipspupupShow = false;
        } else {
          this.$store.commit("MyMessage", res.msg);
        }
      });
    },
    delFun(index) {
      this.placeList.splice(index, 1);
      this.groupAjax.placeList = this.placeList;
      this.$store.commit("MyMessage", "删除成功");
    },
    showFun() {
      this.show = true;
      this.initAMap();
    },
    // 周边搜索
    searchNear(mapNew, title) {
      let that = this;
      that.mapNew.clearMap();
      let placeSearch = new AMap.PlaceSearch({
        // city: '', // 兴趣点城市
        citylimit: true, // 是否强制在设置的城市内搜索，默认false
        type: "", // 兴趣点类别，用‘|’分隔，默认：'餐饮服务|商务住宅|生活服务'
        pageSize: 20, // 每页条数，默认10，范围1-50
        pageIndex: 1, // 页码
        extensions: "all", // 默认base，返回基本地址信息；all：返回详细信息
        // map: AMap, // 地图实例，可选
        // panel: 'panel', // 结果列表的id容器，可选
        autoFitView: true, // 是否自动调整地图视野到marker点都处于可见范围
      });

      // console.log(that.position);
      let keywords = that.keywords, // 关键字
        position = that.position, // 中心点经纬度
        radius = 2000; // 搜索半径 0-50000
      placeSearch.searchNearBy(
        keywords,
        position,
        radius,
        function (status, result) {
          // console.log(that.mapNew, "地图实列");
          // var icon = new AMap.Icon({
          //   size: new AMap.Size(10, 10), // 图标尺寸
          //   image: iconquyu, // Icon的图像
          //   imageOffset: new AMap.Pixel(0, -60), // 图像相对展示区域的偏移量，适于雪碧图等
          //   imageSize: new AMap.Size(40, 50), // 根据所设置的大小拉伸或压缩图片
          // });
          // console.log(icon,"图片");
          new AMap.Marker({
            // position: [116.328942, 39.972298], //不同标记点的经纬度s
            // icon: "http://store.is.autonavi.com/showpic/2f754f895d410592bdf46eeddc379bee",//中心点icon
            title: "aaa",
            clickable: true,
            // content:result.poiList.pois[i].name,
            // text:result.poiList.pois[i].name,
            // offset:[0,50],
            map: that.mapNew,
          });
          // console.log(result.poiList.pois);
          if (result.poiList) {
            for (let i = 0; i < result.poiList.pois.length; i++) {
              new AMap.Marker({
                position: [
                  result.poiList.pois[i].location.lng,
                  result.poiList.pois[i].location.lat,
                ], //不同标记点的经纬度
                // icon: "http://store.is.autonavi.com/showpic/2f754f895d410592bdf46eeddc379bee",
                title: result.poiList.pois[i].name,
                // clickable: true,
                // content: result.poiList.pois[i].name,
                // text: result.poiList.pois[i].name,
                // offset:[0,50],
                map: that.mapNew,
              });
            }
          }
          // console.log(status, result, "结果"); //这个结果可以看出周边的很多信息列表，根据结果做很多事，如果要翻页，请移步官网 在此如有需要  附链接https://lbs.amap.com/api/webservice/guide/api/newpoisearch
        }
      );
    },
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    },
    initAMap() {
      let that = this;
      var markers = [];
      AMapLoader.load({
        key: "0a3647329c1fceff9e14bcd1a27f4f0d",
        version: "2.0",
        plugins: [
          "AMap.CircleEditor", // 圆形编辑器插件
          "AMap.Geolocation", // 定位控件，用来获取和展示用户主机所在的经纬度位置
          "AMap.Geocoder", // 地理编码与逆地理编码服务，用于地址描述与坐标间的相互转换
          "AMap.Autocomplete",
          "AMap.PlaceSearch",
          "AMap.CitySearch",
        ],
      }).then((map) => {
        this.map = new AMap.Map("album", {
          zoom: 13,
          resizeEnable: true,
        });
        AMap.plugin(["AMap.PlaceSearch", "AMap.AutoComplete"], function () {
          that.auto = new AMap.AutoComplete(that.autoOptions);
          that.placeSearch = new AMap.PlaceSearch({
            map: that.map,
          }); //构造地点查询类
          that.auto.on("select", that.select); //注册监听，当选中某条记录时会触发
        });

        function showInfoClick(e) {
          var marker = new AMap.Marker({
            position: new AMap.LngLat(116.39, 39.92),
            icon: "http://a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-default.png",
            extData: {
              id: 1,
            },
          });
          markers = [];
          markers.push(marker);
          var overlayGroups = new AMap.OverlayGroup(markers);
          console.log(overlayGroups);
          that.map.add(overlayGroups);
        }

        that.map.on("click", showInfoClick);
      });
    },
    select(e) {
      let that = this;
      if (this.keywords !== "") {
        this.placeSearch.search(this.keywords); //关键字查询查询
        console.log(this.placeSearch);
        this.placeSearch.search(this.keywords, function (status, result) {
          // 查询成功时，result即对应匹配的POI信息
          console.log("status", status);
          console.log("result", result);
          that.poiList = result.poiList.pois;
          var pois = result.poiList.pois;
          that.poiClick(that.poiList[0]);
          for (var i = 0; i < that.poiList.length; i++) {
            var poi = that.poiList[i];
            that.poiList[i].select = false;
            if (i === 0) that.poiList[i].select = true;
            var marker = [];
            marker[i] = new AMap.Marker({
              position: poi.location, // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
              title: poi.name,
            });
            // 将创建的点标记添加到已有的地图实例：
            that.map.add(marker[i]);
          }
          that.map.setFitView();
        });
      }
    },
    submit() {
      console.log(this.location);
      if (
        !this.location.longitude ||
        !this.location.dimension ||
        !this.location.placeName ||
        !this.location.placeDescribe
      ) {
        this.$store.commit("MyMessage", "请选择打卡地点");
        return false;
      }
      this.placeList.push(this.location);
      this.$emit("addressFun", this.location);
      this.groupAjax.placeList = this.placeList;
      this.show = false;
      this.$forceUpdate();
    },
    poiClick(e, index) {
      console.log(e);
      this.location.longitude = e.location.lng;
      this.location.dimension = e.location.lat;
      this.location.placeName = e.name;
      this.location.placeDescribe = e.address === "" ? e.name : e.address;
      this.map.setCenter([e.location.lng, e.location.lat]);
      this.poiList.forEach((e, i) => {
        if (index === i) {
          e.select = true;
        } else {
          e.select = false;
        }
      });
      console.log(this.location);
      this.$forceUpdate();
    },
    addMarker() {
      let that = this;
      var marker = new AMap.Marker({
        icon: "//a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-default.png",
        position: that.position,
        offset: new AMap.Pixel(-13, -30),
      });
      marker.setMap(that.mapNew);
    },
  },
};
</script>
<style lang="less" scoped>
.album-popup {
  position: absolute;
  left: 15px;
  top: 56px;
  width: 220px;
  max-height: 200px;
  background: #fff;
  z-index: 9;
  border-radius: 5px;
  overflow-y: auto;
  padding: 10px 0;
  .album-popup-box {
    height: 28px;
    line-height: 28px;
    padding: 0 10px;
    text-align: left;
    cursor: pointer;
    .el-icon-check {
      float: right;
      color: #4c8afc;
      position: relative;
      top: 5px;
      font-size: 18px;
    }
  }
}
.album {
  width: 100%;
  height: calc(100% - 60px);
  border-radius: 4px;
  position: relative;
}
.el-menu.el-menu--horizontal {
  border-bottom: none;
}
.el-menu--horizontal > .el-menu-item.is-active {
  border-bottom: none;
  color: #409eff !important;
}
.el-menu--horizontal > .el-menu-item {
  border-bottom: none;
}
.time-footer {
  padding: 15px 32px 0;
  text-align: right;
}
.time-popup {
  width: 700px;
  height: 540px;
  background: #ffffff;
  border-radius: 8px 8px 8px 8px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -350px;
  margin-top: -270px;

  .time-list {
    margin: 0 10px;
    padding: 0 22px;
    height: 402px;
    position: relative;
    .album-box {
      width: 414px;
      position: absolute;
      left: 15px;
      top: 21px;
      height: 34px;
      z-index: 99;
      .album-box-l {
        width: 298px;
        height: 32px;
        background: #ffffff;
        border-radius: 6px 6px 6px 6px;
        opacity: 1;
        border: 1px solid #dbdbdb;
        float: left;
        .album-i {
          width: calc(100% - 91px);
          float: left;
          height: 32px;
          border-radius: 6px 0 0 6px;
          padding-left: 11px;
          font-size: 12px;
          color: #262626;
          &::placeholder {
            color: #bbbbbb;
          }
        }
        .album-s {
          display: inline-block;
          width: calc(80px);
          background: #4c8afc;
          // border-top: 1px solid #4c8afc;
          // border-bottom: 1px solid #4c8afc;
          border-radius: 0px 6px 6px 0px;
          height: 100%;
          cursor: pointer;
          text-align: center;
          line-height: 32px;
          color: #fff;
          font-size: 12px;
        }
      }
      .album-box-r {
        margin-left: 16px;
        width: calc(100% - 316px);
        float: left;
        .el-select {
          width: 100%;
          .el-input__inner {
            width: 100%;
            height: 34px;
          }
        }
      }
    }
  }
}
</style>
<style lang="less">
.album-box-r {
  .el-select {
    width: 100%;
    .el-input__inner {
      width: 100%;
      height: 34px;
    }
    .el-input__icon {
      line-height: 34px;
    }
  }
}
</style>
<style lang="less" scoped>
#album {
  width: 100%;
  height: 100%;
}
.clickIn-box-1 {
  width: 256px;
  display: inline-block;
}
.clickIn-box-2 {
  width: 146px;
  display: inline-block;
  margin-left: 10px;
}
.clickIn-box-3 {
  width: calc(100% - 432px);
  display: inline-block;
  margin-left: 20px;
  text-align: right;
}
.i-clickIn {
  width: calc(100% - 50px);
  height: 178px;
  background: #f2f3f5;
  border-radius: 6px;
  padding-bottom: 23px;
  .clickIn-list {
    padding: 0 22px;
    .clickIn-box {
      width: 100%;
      border-bottom: 1px dashed #bbb;
      padding: 18px 0;
      &.clickIn-box-header {
        padding: 10px 0;
      }
      .clickIn-box-1 {
        width: 256px;
        display: inline-block;
      }
      .clickIn-box-2 {
        width: 86px;
        display: inline-block;
        margin-left: 10px;
      }
      .clickIn-box-3 {
        width: calc(100% - 372px);
        display: inline-block;
        margin-left: 20px;
      }
    }
  }
}
</style>



