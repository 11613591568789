var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"popup-layer"},[_c('div',{staticClass:"popup-layer-bg"}),_c('div',{staticClass:"time-popup"},[_c('div',{staticClass:"popup-layer-close",on:{"click":_vm.timeShowFun}},[_c('svg-icon',{attrs:{"icon-class":"popup-close"}})],1),_c('div',{staticClass:"popup-title"},[_vm._v("新建班次")]),_c('div',{staticClass:"i-title"},[_vm._v("班次名称")]),_c('div',{staticClass:"i-ipt-box"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.ajaxData.shiftName),expression:"ajaxData.shiftName"}],attrs:{"type":"text","placeholder":"不超过30个字符"},domProps:{"value":(_vm.ajaxData.shiftName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.ajaxData, "shiftName", $event.target.value)}}})]),_vm._m(0),_c('div',{staticClass:"time-list webkit-scrollbar"},[_c('div',{staticClass:"shift-main"},_vm._l((_vm.shiftTimeList),function(e,i){return _c('div',{key:i,staticClass:"shift-main-box"},[_c('div',{staticClass:"shift-l"},[_c('p',{staticClass:"shift-p"},[_vm._v("上班打卡")]),_c('div',{staticClass:"shift-box"},[_c('i',{staticClass:"el-icon-arrow-down"}),_c('el-time-select',{attrs:{"placeholder":"起始时间","value-format":"HH:mm","format":"HH:mm","picker-options":{
                  start: '00:00',
                  step: '00:10',
                  end: '23:30',
                }},model:{value:(e.workTime),callback:function ($$v) {_vm.$set(e, "workTime", $$v)},expression:"e.workTime"}})],1)]),_c('div',{staticClass:"shift-c"},[_vm._v("至")]),_c('div',{staticClass:"shift-r"},[_c('p',{staticClass:"shift-p"},[_vm._v("下班打卡")]),_c('div',{staticClass:"shift-box"},[_c('i',{staticClass:"el-icon-arrow-down"}),_c('el-time-select',{attrs:{"placeholder":"起始时间","value-format":"HH:mm","format":"HH:mm","picker-options":{
                  start: '00:00',
                  step: '00:10',
                  end: '23:30',
                  minTime: e.workTime,
                }},model:{value:(e.knockOffTime),callback:function ($$v) {_vm.$set(e, "knockOffTime", $$v)},expression:"e.knockOffTime"}})],1)]),(i !== 0)?_c('div',{staticClass:"shift-del",on:{"click":function($event){return _vm.del(i)}}},[_vm._v("删除")]):_vm._e()])}),0),_c('div',{staticClass:"shift-main-add"},[_c('span',{on:{"click":_vm.addTime}},[_vm._v("+ 新增打卡时段")])])]),_c('div',{staticClass:"time-footer"},[_c('div',{staticClass:"btns-default btns-1",on:{"click":function($event){return _vm.submit()}}},[_vm._v("确 定")]),_c('div',{staticClass:"btns-default btns-2",on:{"click":_vm.timeShowFun}},[_vm._v("取 消")])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"i-title title-b"},[_vm._v(" 打卡时段 "),_c('span',[_vm._v("（计算工时，必须选择）")])])}]

export { render, staticRenderFns }