<template>
  <div class="attendance">
    <div class="attendance-header">
      <svg-icon
        @click="goFun('/attendanceGroup')"
        class="fanhui-icon"
        icon-class="fanhui-icon"
      ></svg-icon
      >修改考勤组
    </div>
    <div class="attendance-body">
      <div class="attendance-main webkit-scrollbar">
        <div class="attendance-main-l">
          <!-- 基本信息-->
          <basicInformation
            :ajaxData="groupAjax"
            @userList="basic"
            @saveGroup="saveGroup"
          />
          <!-- 打卡地址 -->
          <clockIn
            :ajaxData="groupAjax"
            @addressFun="addressFun"
            @saveGroup="saveGroup"
          />
        </div>
        <div class="attendance-main-r">
          <div class="edit-span"></div>
          <!-- 考勤时间 (班次） -->
          <attendanceTime :ajaxData="groupAjax" @saveGroup="saveGroup" />

          <!-- 考勤扣款 -->
          <attendanceDeduction :ajaxData="groupAjax" @saveGroup="saveGroup" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import basicInformation from "./editAttendance/basicInformation.vue";
import clockIn from "./editAttendance/clockIn.vue";
import attendanceDeduction from "./editAttendance/attendanceDeduction.vue";
import attendanceTime from "./editAttendance/attendanceTime.vue";
export default {
  data() {
    return {
      checkedAll: true,
      list: [
        {
          checked: false,
          title: "考勤组1",
        },
        {
          checked: false,
          title: "考勤组2",
        },
        {
          checked: false,
          title: "考勤组3",
        },
        {
          checked: false,
          title: "考勤组4",
        },
        {
          checked: false,
          title: "考勤组5",
        },
        {
          checked: false,
          title: "考勤组6",
        },
      ],
      groupAjax: JSON.parse(sessionStorage.getItem("records")),
    };
  },
  components: {
    basicInformation,
    clockIn,
    attendanceTime,
    attendanceDeduction,
  },
  mounted() {
    // console.log(this.records);
  },
  methods: {
    goFun(url) {
      this.$router.push(url);
    },
    // 基本信息
    basic(userList, groupName) {
      console.log(groupName);
      this.groupAjax.groupName = groupName;
      this.groupAjax.userList = userList;
    },
    // 打卡地址
    addressFun(list) {
      this.groupAjax.placeList.push(list);
      // console.log(this.groupAjax);
    },
    saveGroup() {
      console.log(this.groupAjax);
      sessionStorage.setItem("records", JSON.stringify(this.groupAjax));
    },
  },
};
</script>

<style lang="less" scoped>
.fanhui-icon {
  margin-right: 15px;
  cursor: pointer;
}
.attendance-main {
  overflow-y: auto;
  position: relative;
}
.edit-span {
  position: absolute;
  left: 0;
  height: 100%;
  // width: 1px;
  border-right: 1px dashed #dbdbdb;
}
.attendance-main-l {
  width: calc(50%);
  // border-right: 1px dashed #dbdbdb;
  float: left;
  height: 100%;
  text-align: left;
}
.attendance-main-r {
  position: relative;
  width: calc(50% - 50px);
  float: left;
  // height: 100%;
  text-align: left;
  padding-left: 50px;
}
</style>