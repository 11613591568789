<template>
  <!-- 考勤时间 (班次） -->
  <div>
    <areaTitle
      className="d_jump"
      title="考勤时间 (班次）"
      @saveClick="tipsShowFun"
    />
    <div class="information">
      <div class="i-title">班次设置</div>
      <div class="time-default">
        <!-- 默认班次: 09:00 - 18:00 -->
        <!-- <span class="time-edit" @click="timePopup = true">更改</span> -->
      </div>
      <div class="i-clickIn time-height">
        <div class="clickIn-list">
          <div class="clickIn-box clickIn-box-header">
            <div class="clickIn-box-1">工作日</div>
            <div class="clickIn-box-2">有效范围</div>
            <div class="clickIn-box-3">操作</div>
          </div>
          <div class="clickIn-box" v-for="(e, i) in weekList" :key="i">
            <div class="clickIn-box-1">{{ e.title }}</div>
            <div class="clickIn-box-2">
              <slot v-if="!e.data.timeText">
                {{ e.timeText }}
              </slot>
              <slot v-else> 默认班次:{{ e.data.timeText }} </slot>
            </div>
            <div class="clickIn-box-3">
              <span class="span1" @click="weekListClick(e, i)">更改</span>
            </div>
          </div>
        </div>
      </div>
      <div class="popup-layer" v-if="timePopup">
        <div class="popup-layer-bg"></div>
        <div class="time-popup">
          <div class="popup-layer-close" @click="timePopup = false">
            <svg-icon icon-class="popup-close"></svg-icon>
          </div>
          <div class="popup-title">考勤时间 (班次)</div>
          <div class="i-btn-box">
            <div class="i-btn" @click="shiftShowFun">+ 新建班次</div>
          </div>
          <div class="time-list webkit-scrollbar">
            <div class="time-list-box time-list-box-header">
              <div class="time-list-box-1">班次名称</div>
              <div class="time-list-box-2">打卡时段</div>
            </div>
            <el-radio-group v-model="radioValue">
              <div class="time-list-box" v-for="(e, i) in records" :key="i">
                <div class="time-list-box-1">
                  <el-radio
                    class="list-radio"
                    @change="radioClick(e)"
                    :key="e.shiftId"
                    :label="e.shiftId"
                    >{{ e.shiftName }}</el-radio
                  >
                  <!-- {{ e.shiftName }} -->
                </div>
                <div class="time-list-box-2">
                  {{ e.timeText }}
                </div>
              </div>
            </el-radio-group>
          </div>
          <div class="time-footer">
            <div class="btns-default btns-1" @click="submit">确 定</div>
            <div class="btns-default btns-2" @click="timePopup = false">
              取 消
            </div>
          </div>
        </div>
      </div>
      <addShift
        :shiftId="shiftId"
        v-if="addShiftFlag"
        @closeFun="timeShowFun"
      />
    </div>
    <tipspupup v-if="tipspupupShow" @closeFun="popupClose"></tipspupup>
  </div>
</template>

<script>
import areaTitle from "./areaTitle.vue";
import { queryShift, saveGroup } from "@/http/api";
import addShift from "@/components/addShift";
import tipspupup from "../../../components/tipspupup.vue";
export default {
  data() {
    return {
      tipspupupShow: false,
      enterpriseId: sessionStorage.getItem("enterpriseId"),
      timePopup: false,
      shiftPopup: false,
      addShiftFlag: false,
      radioValue: "",
      shiftId: "",
      records: [],
      radio: "",
      pages: {
        total: 0,
        current: 1,
        pageSize: 90,
        pages: 0,
      },
      weekList: [
        {
          id: 1,
          title: "周一",
          timeText: "休息",
          shiftId: "",
          data: {},
        },
        {
          id: 2,
          title: "周二",
          timeText: "休息",
          shiftId: "",
          data: {},
        },
        {
          id: 3,
          title: "周三",
          timeText: "休息",
          shiftId: "",
          data: {},
        },
        {
          id: 4,
          title: "周四",
          timeText: "休息",
          shiftId: "",
          data: {},
        },
        {
          id: 5,
          title: "周五",
          timeText: "休息",
          shiftId: "",
          data: {},
        },
        {
          id: 6,
          title: "周六",
          timeText: "休息",
          shiftId: "",
          data: {},
        },
        {
          id: 7,
          title: "周日",
          timeText: "休息",
          shiftId: "",
          data: {},
        },
      ],
      timeList: {},
      weekIndex: 0,
      groupAjax: {},
      paramData: [],
    };
  },

  props: {
    ajaxData: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  components: {
    areaTitle,
    addShift,
    tipspupup,
  },
  mounted() {
    this.groupAjax = this.$config.cloneObj(this.ajaxData);
    let timeList = this.groupAjax.timeList;
    console.log(timeList);
    let array = [];
    timeList.forEach((e) => {
      let clockWeekArray = e.clockWeek.split(",");
      clockWeekArray.forEach((e1) => {
        this.weekList.forEach((e2) => {
          if (e2.id * 1 === e1 * 1) {
            // console.log(e1, e.shiftId);
            e2.timeText = e.timeText;
            e2.shiftId = e.shiftId;
            e2.attendanceGroupId = e.attendanceGroupId;
            e2.clockWeekText = e.clockWeekText;
            e2.data = e;
          }
        });
      });
    });
    this.weekFun();
    this.queryShift();
  },
  methods: {
    weekListClick(e, i) {
      this.timePopup = true;
      this.weekIndex = i;
      this.radioValue = e.shiftId;
      this.records.forEach((item, index) => {
        if (e.shiftId === item.shiftId) {
          this.timeList = item;
        }
      });
    },
    closeFun() {},
    weekFun() {
      // this.weekList[this.weekIndex].data = this.timeList;
      // this.weekList[this.weekIndex].shiftId = this.timeList.shiftId;
      // this.timePopup = false;
      const nameArr = []; //存放name，用来查找是否有相同name的情况
      const resultData = []; //合并结果数组
      for (let i = 0; i < this.weekList.length; i++) {
        if (nameArr.indexOf(this.weekList[i].shiftId) === -1) {
          // 没找到相同name的话
          // console.log(this.weekList[i]);
          resultData.push({
            attendanceGroupId: this.groupAjax.attendanceGroupId,
            shiftId: this.weekList[i].shiftId,
            timeText: this.weekList[i].timeText,
            clockWeek: this.weekList[i].id,
            origin: [this.weekList[i]],
            ruleId: this.groupAjax.ruleId,
          });
          nameArr.push(this.weekList[i].shiftId);
        } else {
          // 有相同name合并对象
          for (let j = 0; j < resultData.length; j++) {
            if (resultData[j].shiftId === this.weekList[i].shiftId) {
              resultData[j].origin.push(this.weekList[i]);
              break;
            }
          }
        }
      }

      this.paramData = [];
      resultData.forEach((e, i) => {
        if (e.shiftId) {
          e.clockWeek = "";
          e.origin.forEach((e1, i1) => {
            e.clockWeek += e1.id;
            if (i1 !== e.origin.length - 1) {
              e.clockWeek += ",";
            }
          });
          // delete this.SalaryStaff.wagePayable;
          // console.log(e);
          this.paramData.push({
            attendanceGroupId: e.attendanceGroupId,
            shiftId: e.shiftId,
            ruleId: e.ruleId,
            clockWeek: e.clockWeek,
            timeText: e.timeText,
            enable: 1,
            shift: {
              ...e.origin[0].data,
            },
          });
        }
      });
      // console.log("paramData", this.paramData);
    },
    submit() {
      // 对应的星期进行赋值
      this.weekList[this.weekIndex].data = this.timeList;
      this.weekList[this.weekIndex].shiftId = this.timeList.shiftId;
      this.timePopup = false;
      const nameArr = []; //存放name，用来查找是否有相同name的情况
      const resultData = []; //合并结果数组
      for (let i = 0; i < this.weekList.length; i++) {
        if (nameArr.indexOf(this.weekList[i].shiftId) === -1) {
          // 没找到相同name的话
          console.log(this.weekList[i]);
          resultData.push({
            attendanceGroupId: this.groupAjax.attendanceGroupId,
            shiftId: this.weekList[i].shiftId,
            clockWeek: this.weekList[i].id,
            origin: [this.weekList[i]],
            ruleId: this.groupAjax.ruleId,
          });
          nameArr.push(this.weekList[i].shiftId);
        } else {
          // 有相同name合并对象
          for (let j = 0; j < resultData.length; j++) {
            if (resultData[j].shiftId === this.weekList[i].shiftId) {
              resultData[j].origin.push(this.weekList[i]);
              break;
            }
          }
        }
      }

      this.paramData = [];
      resultData.forEach((e, i) => {
        if (e.shiftId) {
          e.clockWeek = "";
          e.origin.forEach((e1, i1) => {
            e.clockWeek += e1.id;
            if (i1 !== e.origin.length - 1) {
              e.clockWeek += ",";
            }
          });
          // delete this.SalaryStaff.wagePayable;
          console.log(e);
          this.paramData.push({
            attendanceGroupId: e.attendanceGroupId,
            shiftId: e.shiftId,
            ruleId: e.ruleId,
            clockWeek: e.clockWeek,
            enable: 1,
            timeText: e.origin[0].data.timeText,
            shift: {
              ...e.origin[0].data,
            },
          });
        }
      });
      console.log(this.paramData);
      return;
      // this.groupAjax.timeList = paramData;
      // this.saveGroup();
      this.$emit("timeFun", this.paramData);
      return;
    },
    tipsShowFun() {
      this.tipspupupShow = true;
    },
    popupClose(type) {
      if (type === 0) {
        this.tipspupupShow = false;
      } else {
        this.groupAjax.enable = type;
        this.saveGroup();
      }
      this.$forceUpdate();
    },
    saveGroup() {
      this.groupAjax.timeList = this.paramData;
      saveGroup(this.groupAjax).then((res) => {
        if (res.code === 0) {
          console.log(res);
          this.ajaxData.timeList = this.$config.cloneObj(
            this.groupAjax.timeList
          );
          this.$emit("saveGroup");
          this.$store.commit("MyMessage", "保存成功");
          this.tipspupupShow = false;
        } else {
          this.$store.commit("MyMessage", res.msg);
        }
      });
    },
    radioClick(e) {
      // console.log(e);
      // this.shiftId = e.shiftId;
      delete e.createTime;
      delete e.updateTime;
      this.timeList = e;
      // console.log(this.timeList);
    },
    queryShift() {
      let param = {
        enterpriseId: this.enterpriseId,
        pageNo: this.pages.current,
        pageSize: this.pages.pageSize,
      };
      queryShift(param).then((res) => {
        if (res.code === 0) {
          // console.log(res);
          this.records = res.data.records;
          this.records.forEach((e) => {
            e.timeText = "";
            e.shiftTimeList.forEach((e1, i) => {
              // shiftName
              delete e1.createTime;
              delete e1.updateTime;
              e.timeText += e1.workTime + "-" + e1.knockOffTime;
              if (i !== e.shiftTimeList.length - 1) {
                e.timeText += "; ";
              }
            });
          });
        }
      });
    },
    timeShowFun() {
      this.addShiftFlag = false;
      this.timePopup = true;
    },
    shiftShowFun() {
      this.addShiftFlag = true;
      this.timePopup = false;
    },
  },
};
</script>
<style lang="less">
.el-radio-group {
  font-size: unset;
  width: 100%;
}
</style>
<style lang="less" scoped>
.i-clickIn {
  width: calc(100% - 50px);
}
.i-btn-box {
  margin: 0 32px 20px;
  width: calc(100% - 64px);
  .i-btn {
    // width: 100px;
    padding: 0 15px;
    cursor: pointer;
  }
}
.time-footer {
  padding: 15px 32px 0;
  text-align: right;
}
.time-popup {
  width: 700px;
  height: 540px;
  // width: 640px;
  // height: 630px;
  background: #ffffff;
  border-radius: 8px 8px 8px 8px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -350px;
  margin-top: -270px;

  .time-list {
    margin: 0 10px;
    padding: 0 22px;
    height: 342px;
    overflow-y: auto;
    .time-list-box {
      text-align: left;
      padding: 28px 0 20px;
      // padding: 20px 0 16px;
      display: table;
      width: 100%;
      border-bottom: 1px dashed #dbdbdb;

      &.time-list-box-header {
        height: 50px;
        background: #f2f3f5;
        line-height: 50px;
        padding: 0;
        border-bottom: none;
        .time-list-box-2 {
          color: #5a6370;
          font-weight: 400;
        }
        .time-list-box-1 {
          color: #5a6370;
          font-weight: 400;
          padding-left: 46px;
          width: 236px;
        }
      }
      .time-list-box-1 {
        width: 260px;
        color: #262626;
        font-weight: bold;
        padding-left: 20px;
        position: relative;
        display: inline-block;
        vertical-align: middle;
        display: table-cell; /*cell垂直居中，如果外层div不为table则tablecell须有高度*/
      }
      .time-list-box-2 {
        width: calc(100% - 262px);
        position: relative;
        color: #262626;
        font-weight: bold;
        display: inline-block;
        vertical-align: middle;
        display: table-cell; /*cell垂直居中，如果外层div不为table则tablecell须有高度*/
      }
    }
  }
}
.time-default {
  font-weight: bold;
  color: #262626;
  font-size: 12px;
  margin-bottom: 10px;
}
.time-height {
  height: auto;
}
.time-edit {
  font-size: 12px;
  font-weight: 400;
  color: #4c8afc;
  // margin-left: 20px;
  margin-left: 0px;
  cursor: pointer;
}
.clickIn-box-header {
  .clickIn-box-1 {
    color: #5a6370;
    font-weight: 400;
  }
  .clickIn-box-2 {
    color: #5a6370;
    font-weight: 400;
  }
}
.clickIn-box-1 {
  width: 80px;
  display: inline-block;
  font-weight: bold;
  color: #262626;
}
.clickIn-box-2 {
  width: calc(100% - 206px);
  display: inline-block;
  margin-left: 10px;
  font-weight: bold;
  color: #262626;
}
.clickIn-box-3 {
  width: 96px;
  display: inline-block;
  margin-left: 20px;
  text-align: right;
  .span1 {
    margin-right: 0;
  }
}
</style>